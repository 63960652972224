import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { ICustomer } from "../../types/ICustomer"
import { useParams } from "react-router-dom"
import { useGetStore } from "../../hooks/firestore/useGetStore.ts"
import { useGetStoreOrders } from "../../hooks/firestore/useGetStoreOrders.ts"
import { mapOrderItemsToStoreColumns } from "./functions/mapOrderItemsToStoreColumns.ts"
import { stackCustomerItems } from "./functions/stackCustomerItems.ts"
import { getTotalPrice } from "./functions/getTotalPrice.ts"

interface ICustomerDetailsCompactProps {
  customer: ICustomer
}

const CustomerDetailsCompact: React.FC<ICustomerDetailsCompactProps> = ({
  customer,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const { storeId, ownerId } = useParams()
  const { store } = useGetStore(ownerId, storeId)
  const { storeOrders } = useGetStoreOrders(ownerId, storeId, customer?.email)
  const orders =
    store && storeOrders ? mapOrderItemsToStoreColumns(store, storeOrders) : []
  const items = stackCustomerItems(orders)
  return (
    <>
      {open && (
        <CardContent>
          {store?.comment && (
            <Typography variant="body1" gutterBottom>
              {store.comment}
            </Typography>
          )}
          <Stack gap={3}>
            {items.map((i) => (
              <>
                <Stack key={i.id}>
                  <Typography variant="body2">{i.name}</Typography>
                  <Typography variant="body2">
                    Price: {i.price} {store?.currency}
                  </Typography>
                  <Typography variant="body2">
                    Quantity: {i.quantity}
                  </Typography>

                  <Typography variant="body2">
                    Total: {i.price * i.quantity} {store?.currency}
                  </Typography>
                </Stack>
                <Divider />
              </>
            ))}
            <Stack alignItems="flex-end">
              <Box sx={{ mt: 3, mr: 2 }}>
                <Typography variant="body2">
                  Customer total: {getTotalPrice(items)} {store?.currency}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      )}
      <CardActions>
        {open ? (
          <Button onClick={() => setOpen(false)} variant="text" size="small">
            Hide
          </Button>
        ) : (
          <Button onClick={() => setOpen(true)} variant="text" size="small">
            Details
          </Button>
        )}
      </CardActions>
    </>
  )
}

export default CustomerDetailsCompact
